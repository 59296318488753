import { Service } from './service'

const PATH = 'white-label'

class WhiteLabelService extends Service {
  getOffices () {
      return this.get(PATH + '/offices')
  }
}

export default new WhiteLabelService()
