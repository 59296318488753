import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import supportService from '@/services/support.service'
import WhiteLabelService from '@/services/white-label.service'
import EventBus from '@/util/EventBus'

export default {
  name: 'Suport',
  components: { HeaderTopDashboard },
  data: function () {
    return {
      messageResult: 0,
      supportMessage: {
        subject: null,
        message: null,
      },
      offices: [],
    }
  },
  mounted () {
    this.loadOffices()
  },
  methods: {
    loadOffices () {
      WhiteLabelService.getOffices()
      .then((response) => {
        // translate opening_hours ?
        this.offices = response
      })
    },
    sendForm () {
      const data = {
        subject: this.supportMessage.subject,
        message: this.supportMessage.message,
      }
      supportService.sendSupportMessage(data)
      .then(
        (response) => {
          this.messageResult = response.sent
          EventBus.$emit('showAlert', 'success', this.$t('Se ha enviado correctamente el mensaje.'))
        },
        () => {
          this.messageResult = 0
          EventBus.$emit('showAlert', 'danger', this.$t('Se ha producido un error al enviar el mensaje.'))
        },
      )
      .finally(_ => {
        this.showFormInputs = false
        this.clearForm()
      })
    },
    clearForm () {
      this.supportMessage = {
        subject: null,
        message: null,
      }
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },
  },
}

